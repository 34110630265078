import React, { useEffect, useState } from 'react';
import { getList } from '../../common/BackendProvider';
import { toast } from 'react-toastify';
import { Link, useSearchParams } from "react-router-dom";
import { NewsListItem } from './NewsListItem';
import { NewsFilter } from './NewsFilter';

export const NewsList =() => {
    const[news, setNews] = useState([])
    const[esgKeywords, setEsgKeywords] = useState([])
    const[esgKeyword, setEsgKeyword] = useState(null)
    const[score, setScore] = useState(0)
    const[loading, setLoading] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams();
    let page = searchParams.get('page') ? parseInt(searchParams.get('page')) : 1
    if (page < 1) {
        page = 1
    }
    useEffect(() => {
        setLoading(true)
        getList("esg_keyphrases",{}).then((response) => {
            setEsgKeywords(response.data.esgkeyphrases)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading esg_keywords", {})
                setLoading(false)
            }
        )
    },[])
    useEffect(() => {
        setLoading(true)
        BackendProvider.getList("news", {filter: {keyword: esgKeyword, score: score}, pagination:{page: page, perPage: 20}}).then((response) => {
            setNews(response.data.news)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading news", {})
                setLoading(false)
            }
        )
    },[page, esgKeyword, score])

    const handleKeywordChange = (e) => {
        setEsgKeyword(e.target.value)
    }

    const handleScoreChange = (e) => {
        if (e.target === undefined) {
            setScore(0)
            return
        }
        setScore(e.target.value)
    }

    return(<div>
        <h1>News List</h1>
        <NewsFilter esgKeywords={esgKeywords} onChange={handleKeywordChange} onScoreChange={handleScoreChange}/>
        <div className="mt-16">
        {
            news.map((newsitem) => {
              return <NewsListItem key={newsitem.id} newsitem={newsitem} />
            })
        }
        </div>
        <div>
            <span className='p-2'><Link to = {`/news/?page=1`  }>First</Link></span>
            <span className='p-2'><Link to = {`/news/?page=${page-1}`  }>Prev</Link></span>
            <span className='p-2'>{page}</span>
            <span className='p-2'><Link to = {`/news/?page=${page+1}`  }>Next</Link></span>
            {/* <span className='p-2'><Link to = {`/news/?page=4`  }>Last</Link></span> */}
        </div>
    </div>)
}