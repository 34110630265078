import * as React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {AuthContext} from '../App'
import Panel from "../layout/components/Panel";
import Input from "../layout/components/Input";
import Button from "../layout/components/Button";

const useAuth = () => {
    return React.useContext(AuthContext);
}

export function Login({show, onClose}) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [loginInvalid, setLoginInvalid] = useState(false);
    let location = useLocation();
    let navigate = useNavigate();

    const { onLogin, onLoad } = useAuth();

    const handleLogin = () => {
        setLoading(true);
        onLogin(email, password, onFailure);
        setLoading(false);
    }

    const onFailure =()=> {
        toast.error("Login failed", {})
    }

    React.useEffect(() => {
        onLoad();
    }, []);

    return(
        <>
            <ToastContainer/>
            <Panel title="Sign In">      
                <p className="text-normal text-center text-general-80">New to Our Product? &nbsp;
                    <Link to="/register" className="text-primary-100">Create an Account</Link>
                </p>
                <div className="form-group mb-4 mt-8 text-center">
                    <Input
                        label="Email"
                        type="email"
                        placeholder="Enter Email Address"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="form-group mb-4 text-center">
                    <Input
                        label="Password"
                        type="password"
                        id="password"
                        placeholder="Enter Password"
                        onChange={(e) => setPassword(e.target.value)}
                        aria-describedby="passwordHelpBlock"
                    />
                </div>
                <Button
                    className="w-full mt-4"
                    variant="primary" 
                    disabled={loading}
                    onClick={() => handleLogin()}  
                >Login</Button>
                <p className="text-normal text-center mt-4">
                    <Link to="/resetpass" className="text-primary-100">Forgot your password?</Link>
                </p>
            </Panel>
        </>
    )
}


