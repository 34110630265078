import { ArrowLeft, ArrowRight } from './images/Arrows';

export const Pagination = ({page, pages, onPageChange, loading=false}) => {
    const handlePageChange = (page) => {
        onPageChange(page)
    }
    return (
        <div className="flex ">
            {!loading && page > 1 &&(
            <button
                className={`px-4 py-2 mx-1 bg-white text-general-100'}`}
                onClick={() => handlePageChange(page-1)}
            >
                <ArrowLeft/>
            </button>
            ) || <div className="w-16 bg-white"></div>}
        {!loading && Array.from({ length: pages }, (_, index) => {
            if (index > page - 6 && index < page + 4) {
            return(
            <button
                key={index}
                className={`px-4 py-2 mx-1 ${page === index + 1 ? 'bg-primary-30 text-primary-100 rounded' : 'bg-white text-general-100'}`}
                onClick={() => handlePageChange(index + 1)}
            >
                {index + 1}
            </button>
        )}})}
        {!loading && page < pages &&(
            <button
                    className={`px-4 py-2 mx-1 bg-white text-general-100'}`}
                    onClick={() => handlePageChange(page+1)}
                >
                    <ArrowRight/>
            </button>
        )}
        </div>
    )
}