import * as React from 'react';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import FlagIcon from '@mui/icons-material/Flag';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { Panel } from '../../../layout/Panel';
import RiskTabChart from './CompanyLineChart';
import PieChartWithPaddingAngle from './PieChartWithPaddingAngle';

const RiskTile = ({risk}) => {
    return(
        <div className="p-4 bg-white text-gray-600 bg-background body-background-color rounded border border-primary-30 shadow-shadow-base text-left">
            <div className="flex">
            <div className="p-2">{risk.icon}</div>
            <div className="p-2 text-xl font-bold">{risk.title}</div>
            </div>
            <p className="text-sm text-gray-600">{risk.description}</p>
        </div>
    )
}

export const RiskTab =({risk1, risk2, risk3, symbol}) => {
    const settings = {
        width: 100,
        height: 100,
        startAngle: -110,
        endAngle: 110
    };
    const risks = [
        {
            "title": "Severe Negative Supply Shock", 
            "description": "A severe negative supply shock could result from a significant disruption to the supply chain, such as a natural disaster or a pandemic. This could lead to a sharp increase in prices and a decline in output, which could have a negative impact on the economy.",
            "icon": <LocalShippingIcon style={{ fontSize: 40, color: "#FF5733" }}/>
        }, {
            "title": "Severe Negative Demand Shock", 
            "description": "A severe negative demand shock could result from a sharp decline in consumer spending, such as during a recession. This could lead to a decline in output and an increase in unemployment, which could have a negative impact on the economy.",
            "icon": <ShoppingCartIcon style={{ fontSize: 40, color: "#FF5733" }}/>
        }, {
            "title": "Severe domestic and global recessions, countercyclical approach to severity", 
            "description": "Severe domestic and global recessions could result from a combination of negative supply and demand shocks. A countercyclical approach to severity could involve implementing policies to stimulate the economy during a recession, such as increasing government spending or cutting interest rates.",
            "icon": <TrendingDownIcon  style={{ fontSize: 40, color: "#FF5733" }}/>
        }, {
            "title": "Domestic Recession", 
            "description": "A domestic recession could result from a decline in consumer spending, such as during a period of economic uncertainty. This could lead to a decline in output and an increase in unemployment, which could have a negative impact on the economy.",
            "icon": <FlagIcon  style={{ fontSize: 40, color: "#FF5733" }}/>
        }, {
            "title": "Global Recession", 
            "description": "A global recession could result from a decline in global economic activity, such as during a period of economic uncertainty. This could lead to a decline in output and an increase in unemployment, which could have a negative impact on the economy.",
            "icon": <PublicOffIcon style={{ fontSize: 40, color: "#FF5733" }}/>
        }, {
            "title": "Financial Market Stress", 
            "description": "Financial market stress could result from a decline in investor confidence, such as during a period of economic uncertainty. This could lead to a decline in asset prices and an increase in market volatility, which could have a negative impact on the economy.",
            "icon": <MoneyOffIcon  style={{ fontSize: 40, color: "#FF5733" }}/>
        }
    ]
      // 0-AMZN 1-GOOG
    const riskData = [
        [
            { name: '2017', scope1: 0.000, scope2: 0.000, scope3: 0.000, scope123: 0.000 },
            { name: '2018', scope1: 0.961, scope2: 0.909, scope3: 6.699, scope123: 8.569 },
            { name: '2019', scope1: 1.112, scope2: 1.062, scope3: 7.703, scope123: 9.876 },
            { name: '2020', scope1: 1.857, scope2: 1.017, scope3: 8.830, scope123: 11.704 },
            { name: '2021', scope1: 2.386, scope2: 0.802, scope3: 10.906, scope123: 14.093 },
            { name: '2022', scope1: 2.664, scope2: 0.628, scope3: 10.856, scope123: 14.148 },
            { name: '2023', scope1: 2.911, scope2: 0.569, scope3: 10.559, scope123: 14.039 },
            { name: '2024', scope1: 3.600, scope2: 0.452, scope3: 11.670, scope123: 15.722 },
            { name: '2030', scope1: 6.82, scope2: 0.00, scope3: 17.99, scope123: 24.81 },
            { name: '2040', scope1: 13.40, scope2: 0.00, scope3: 30.72, scope123: 44.12 },
            { name: '2050', scope1: 21.79, scope2: 0.00, scope3: 46.79, scope123: 68.57 },
        ],[
            { name: '2017', scope1: 0.013, scope2: 0.637, scope3: 0.525, scope123: 1.175 },
            { name: '2018', scope1: 0.012, scope2: 0.839, scope3: 2.756, scope123: 3.607 },
            { name: '2019', scope1: 0.016, scope2: 0.988, scope3: 1.690, scope123: 2.693 },
            { name: '2020', scope1: 0.011, scope2: 1.132, scope3: 1.467, scope123: 2.610 },
            { name: '2021', scope1: 0.013, scope2: 1.296, scope3: 1.751, scope123: 3.059 },
            { name: '2022', scope1: 0.018, scope2: 1.609, scope3: 2.007, scope123: 3.634 },
            { name: '2023', scope1: 0.016, scope2: 1.888, scope3: 2.206, scope123: 4.109 },
            { name: '2024', scope1: 0.018, scope2: 2.075, scope3: 2.327, scope123: 4.419 },
            { name: '2030', scope1: 0.02, scope2: 3.61, scope3: 3.37, scope123: 7.00 },
            { name: '2040', scope1: 0.03, scope2: 6.74, scope3: 5.45, scope123: 12.22 },
            { name: '2050', scope1: 0.05, scope2: 10.71, scope3: 8.07, scope123: 18.83 },
        ]
    ]
    let graphData = []
    if (symbol == "GOOGL") {
        graphData = riskData[1]
    } else if (symbol == "AMZN") {
        graphData = riskData[0]
    }
    return(
        <Panel>
            <div className="md:flex justify-between w-2/3">
                <div className="flex">  
                    <Gauge
                        value={risk1}
                        {...settings}
                        cornerRadius="50%"
                        sx={(theme) => ({
                            [`& .${gaugeClasses.valueText}`]: {
                            fontSize: 24,
                            },
                            [`& .${gaugeClasses.valueArc}`]: {
                            fill: '#52b202',
                            },
                            [`& .${gaugeClasses.referenceArc}`]: {
                            fill: theme.palette.text.disabled,
                            },
                        })}
                        text={
                            ({ value }) => `${risk1} `
                        }
                    />
                    <div className="text-center pt-12 pl-4">Regulatory Risk</div>
                </div>
                <div className="flex">  
                    <Gauge
                        value={risk2}
                        {...settings}
                        cornerRadius="50%"
                        sx={(theme) => ({
                            [`& .${gaugeClasses.valueText}`]: {
                            fontSize: 24,
                            },
                            [`& .${gaugeClasses.valueArc}`]: {
                            fill: '#1d4ed8',
                            },
                            [`& .${gaugeClasses.referenceArc}`]: {
                            fill: theme.palette.text.disabled,
                            },
                        })}
                        text={
                            ({ value }) => `${risk2} `
                        }
                    />
                    <div className="text-center pt-12 pl-4">Material Risk</div>
                </div>
                <div className="flex">  
                    <Gauge
                        value={risk3}
                        {...settings}
                        cornerRadius="50%"
                        sx={(theme) => ({
                            [`& .${gaugeClasses.valueText}`]: {
                            fontSize: 24,
                            },
                            [`& .${gaugeClasses.valueArc}`]: {
                            fill: '#eab308',
                            },
                            [`& .${gaugeClasses.referenceArc}`]: {
                            fill: theme.palette.text.disabled,
                            },
                        })}
                        text={
                            ({ value }) => `${risk3} `
                        }
                    />
                    <div className="text-center pt-12 pl-4">Reputational Risk</div>
                </div>
            </div>  
            <div className="flex flex-col">
            {(symbol == "GOOGL" || symbol=="AMZN") &&
            <>
                <div className="text-center text-lg">Climate Cost - USD billions (2017 - 2050)</div>
                <div className="text-sm p-4">Our US Government-based Climate Cost Metric (CCM) estimates the economic harm in dollars caused by an entity's GHG emissions over any custom period. We have other discount rates and time series available in the app.  </div>
                <div className="h-96 w-full"><RiskTabChart data={graphData}/></div>
            </>
            }
        </div>
        </Panel>
    )
}