import { Link, useLocation } from "react-router-dom"
import {DashboardIcon, InvestmentProfilesIcon, CompaniesIcon, DealRoomIcon, LearningIcon, ContactUsIcon, WatchlistIcon} from './images/MenuIcons';
import * as cx from 'classnames';

const MenuGroup = ({title, items, onClick}) => {
    const location = useLocation();
    const handleClick = () => {
        onClick()
    }
    return (
        items.map((item, index) => (
        <div key={index} className={cx("p-3 mt-2 rounded", location.pathname===item.path ?  " bg-primary-100 text-white": "text-general-80")}>
            <Link to = {item.path} onClick={handleClick}>
            <div className="flex justify-start">
                {location.pathname===item.path ? item.iconActive : item.icon}
                <div className="ml-2 w-full font-semibold text-sm pt-1">{item.title}</div>
            </div>
            </Link>
        </div>
        ))
    )
}

export const LeftMenu = ({onClick}) => {

    const menuItems1 = [
        {icon: <DashboardIcon fill="general-70" />, iconActive: <DashboardIcon fill="white"/>, path: "/dashboard", title: "My Dashboard"},
        {icon: <InvestmentProfilesIcon fill="general-70"/>, iconActive: <InvestmentProfilesIcon fill="white"/>, path: "/investment_profiles", title: "Investment Profiles"},
        {icon: <CompaniesIcon fill="general-70" />, iconActive: <CompaniesIcon fill="white"/>, path: "/companies", title: "Companies"},
        {icon: <WatchlistIcon fill="general-70"/>, iconActive: <WatchlistIcon fill="white"/>, path: "/watchlist", title: "Watch List"},
        {icon: <DealRoomIcon fill="general-70"/>, iconActive: <DealRoomIcon fill="white"/>, path: "/deal_room", title: "Deal Room"},
    ]

    const menuItems2 = [
        {icon: <LearningIcon fill="general-70" />, iconActive: <LearningIcon fill="white" />, path: "/learning", title: "Learning"},
        {icon: <ContactUsIcon fill="general-70" />, iconActive: <ContactUsIcon fill="white" />, path: "/contactus", title: "Contact Us"},
    ]

    const handleClick = () => {
        onClick()
    }
    return (
        <div className="sticky top-0 flex no-wrap flex-col items-left mt-4 mx-4 w-full lg:w-auto z-30 bg-general-30">
            <MenuGroup title="Main" items={menuItems1} onClick={handleClick}/>       
                <div className="text-general-60 text-sm mt-8 mb-6 ml-2">Support</div>
            <MenuGroup title="Main" items={menuItems2} onClick={handleClick}/>
        </div>
    )
}