import React, {useEffect, useState} from "react";
import {BrowserRouter, Navigate, Routes, Route, useLocation, useNavigate} from "react-router-dom";
import './App.css';
import { Dashboard } from './pages/Dashboard.jsx';
import { CompanyPage } from './pages/company/Company.jsx';
import { Companies } from './pages/companies/Companies.jsx';
import { NewsList } from './pages/news/NewsList.jsx';
import { NewsItem } from './pages/news/NewsItem.jsx';
import { Profile } from './pages/Profile.jsx';
import { PrivateHome } from './layout/PrivateHome.jsx';
import { PublicHome } from './layout/PublicHome.jsx';
import { Login } from './pages/Login.jsx';
import { Register } from './pages/Register.jsx';
import { RegisterConfirmEmail } from './pages/RegisterConfirmEmail.jsx';
import { RegisterSuccess } from './pages/RegisterSuccess.jsx';
import { ResetPass } from './pages/ResetPass.jsx';
import { ResetPassConfirm } from './pages/ResetPassConfirm.jsx';
import {Auth}  from './common/AuthProvider';
import {AdminPrivateHome} from './layout/admin/AdminPrivateHome.jsx';
import {NotFound} from './pages/NotFound.jsx';
import { InvestmentProfiles } from "./pages/InvestmentProfiles.jsx";
import { InvestmentProfilesCreate } from "./pages/InvestmentProfilesCreate.jsx";
import { InvestmentProfilesEdit } from "./pages/InvestmentProfilesEdit.jsx";
import { Watchlist } from "./pages/Watchlist.jsx";
import { DealRoom } from "./pages/DealRoom.jsx";
import { Learning } from "./pages/Learning.jsx";
import { ContactUs } from "./pages/ContactUs.jsx";


export default function App() {
  return (
    <div className="[font-family:'Inter']">
      <BrowserRouter>
      <AuthProvider>
          <Routes>
            <Route path="/" element={<PublicHome><Login /></PublicHome>} />
            <Route path="/login" element={<PublicHome><Login /></PublicHome>} />
            <Route path="/register/success" element={<PublicHome><RegisterSuccess /></PublicHome>} />
            <Route path="/register/confirm_email/:code" element={<PublicHome><RegisterConfirmEmail /></PublicHome>} />
            <Route path="/register" element={<PublicHome><Register /></PublicHome>} />
            <Route path="/resetpass" element={<PublicHome><ResetPass /></PublicHome>} />
            <Route path="/resetpass_confirm/:code" element={<PublicHome><ResetPassConfirm /></PublicHome>} />
            <Route path="/companies" element={<ProtectedRoute><Companies/></ProtectedRoute>} />
            <Route path="/investment_profiles" element={<ProtectedRoute><InvestmentProfiles/></ProtectedRoute>} />
            <Route path="/investment_profiles/create" element={<ProtectedRoute><InvestmentProfilesCreate/></ProtectedRoute>} />
            <Route path="/investment_profiles/:id" element={<ProtectedRoute><InvestmentProfilesEdit/></ProtectedRoute>} />
            <Route path="/watchlist" element={<ProtectedRoute><Watchlist/></ProtectedRoute>} />
            <Route path="/deal_room" element={<ProtectedRoute><DealRoom/></ProtectedRoute>} />
            <Route path="/learning" element={<ProtectedRoute><Learning/></ProtectedRoute>} />
            <Route path="/contactus" element={<ProtectedRoute><ContactUs/></ProtectedRoute>} />
            <Route path="/company/:symbol" element={<ProtectedRoute><CompanyPage/></ProtectedRoute>} />
            <Route path="/dashboard" element={<ProtectedRoute><Dashboard/></ProtectedRoute>} />
            <Route path="/news/:newsID" element={<ProtectedRoute><NewsItem/></ProtectedRoute>} />
            <Route path="/news" element={<ProtectedRoute><NewsList/></ProtectedRoute>} />
            <Route path="/my/profile" element={<ProtectedRoute><Profile/></ProtectedRoute>} />
            <Route path="/*" element={<PublicHome><NotFound/></PublicHome>} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export const AuthContext = React.createContext(null);

const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const initialPublicPages = ["/login", "/signup", "/"]
    var isPublicPage = (initialPublicPages.indexOf(location.pathname) > -1);
    const [loggedIn, setLoggedIn] = useState(!isPublicPage);
    const handleLogin = async (email, password, onFailure) => {
        Auth.login({email: email, password: password})
            .then((response)=>{
                setLoggedIn(true);
                let areaLoginPath = '/dashboard';
                // if (response.area === 'admin') {
                //     areaLoginPath = '/admin/dashboard';
                // }
                console.log(response.data);
                localStorage.setItem('user', JSON.stringify(response.data.user));
                const origin = location.state?.from?.pathname || areaLoginPath;
                navigate(origin);
            })
            .catch((err)=>{
                setLoggedIn(false);
                onFailure()
            })
    };

    const handleCsrfToken = () => {
        Auth.getCsrfToken()
    }

    const handleLogout = () => {
        Auth.logout()
        setLoggedIn(false);
    };

    const value = {
        loggedIn: loggedIn,
        onLoad: handleCsrfToken,
        onLogin: handleLogin,
        onLogout: handleLogout,
    };
    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => {
    return React.useContext(AuthContext);
};

const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    let { loggedIn } = useAuth();
    if ( loggedIn ) {
        const user = JSON.parse(localStorage.getItem('user'));
        return <PrivateHome user={user}>{children}</PrivateHome>;
    }
    return <PrivateHome><Navigate to="/login" replace state={{ from: location }} /></PrivateHome>;
};


const ProtectedAdminRoute = ({ children }) => {
    const location = useLocation();
    let { loggedIn } = useAuth();
    if ( loggedIn ) {
        return <AdminPrivateHome>{children}</AdminPrivateHome>;
    }
    return <AdminPrivateHome><Navigate to="/login" replace state={{ from: location }} /></AdminPrivateHome>;
};

const NoMatch = () => {
    return <p>There's nothing here: 404!</p>;
};
