import * as React from 'react';
import { Panel } from '../../../layout/Panel';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import RiskTabChart from './CompanyLineChart';

export const EsgTab =({esgScore, symbol}) => {

    // 0-AMZN 1-GOOG
    const riskData = [
        [
            { name: '2017', scope1: 1.09, scope2: 1.09, scope3: 1.09, scope123: 1.09 },
            { name: '2018', scope1: 1.09, scope2: 1.09, scope3: 1.10, scope123: 1.10 },
            { name: '2019', scope1: 1.09, scope2: 1.09, scope3: 1.10, scope123: 1.10 },
            { name: '2020', scope1: 1.09, scope2: 1.09, scope3: 1.11, scope123: 1.12 },
            { name: '2021', scope1: 1.09, scope2: 1.09, scope3: 1.12, scope123: 1.12 },
            { name: '2022', scope1: 1.10, scope2: 1.09, scope3: 1.12, scope123: 1.13 },
            { name: '2023', scope1: 1.10, scope2: 1.09, scope3: 1.13, scope123: 1.14 },
            { name: '2024', scope1: 1.10, scope2: 1.09, scope3: 1.13, scope123: 1.14 },
            { name: '2030', scope1: 1.11, scope2: 1.09, scope3: 1.15, scope123: 1.18 },
            { name: '2040', scope1: 1.13, scope2: 1.09, scope3: 1.20, scope123: 1.24 },
            { name: '2050', scope1: 1.16, scope2: 1.09, scope3: 1.25, scope123: 1.32 },
        ],[
            { name: '2017', scope1: 1.09, scope2: 1.09, scope3: 1.09, scope123: 1.09 },
            { name: '2018', scope1: 1.09, scope2: 1.09, scope3: 1.10, scope123: 1.10 },
            { name: '2019', scope1: 1.09, scope2: 1.09, scope3: 1.10, scope123: 1.10 },
            { name: '2020', scope1: 1.09, scope2: 1.10, scope3: 1.10, scope123: 1.11 },
            { name: '2021', scope1: 1.09, scope2: 1.10, scope3: 1.10, scope123: 1.11 },
            { name: '2022', scope1: 1.09, scope2: 1.10, scope3: 1.10, scope123: 1.11 },
            { name: '2023', scope1: 1.09, scope2: 1.10, scope3: 1.10, scope123: 1.11 },
            { name: '2024', scope1: 1.09, scope2: 1.10, scope3: 1.11, scope123: 1.12 },
            { name: '2030', scope1: 1.09, scope2: 1.11, scope3: 1.11, scope123: 1.14 },
            { name: '2040', scope1: 1.09, scope2: 1.13, scope3: 1.13, scope123: 1.17 },
            { name: '2050', scope1: 1.09, scope2: 1.16, scope3: 1.15, scope123: 1.21 },
 
        ]
    ]

    const settings = {
        width: 100,
        height: 100,
        startAngle: -110,
        endAngle: 110
    };
    const envScore = esgScore.environment
    const totalScore = esgScore.total
    const socialScore = esgScore.social
    const governanceScore = esgScore.governance
    let graphData = []
    if (symbol == "GOOGL") {
        graphData = riskData[1]
    } else if (symbol == "AMZN") {
        graphData = riskData[0]
    }
    return(
    <Panel className="mb-4">
        <div className="md:w-96 ml-4 mt-4 mb-4 md:flex">
            <div>
                <div className="text-center">Total ESG Score</div>
                <div className="flex align-center">
                    <Gauge
                        value={totalScore}
                        width={400}
                        height={200}
                        startAngle={-110}
                        endAngle={110}
                        cornerRadius={"50%"}
                        sx={(theme) => ({
                            [`& .${gaugeClasses.valueText}`]: {
                            fontSize: 40,
                            },
                            [`& .${gaugeClasses.valueArc}`]: {
                            fill: '#52b202',
                            },
                            [`& .${gaugeClasses.referenceArc}`]: {
                            fill: theme.palette.text.disabled,
                            },
                        })}
                        text={
                            ({ value }) => `${totalScore} `
                        }
                    /></div>
                <div>Peer group ESG scores:</div>
                <div className="flex justify-between pr-12"><span>max:</span> <span className="font-bold">{esgScore.peer_esg_score_performance_min}</span></div>
                <div className="flex justify-between pr-12"><span>min:</span> <span className="font-bold">{esgScore.peer_esg_score_performance_max}</span></div>
                <div className="flex justify-between pr-12"><span>avg:</span> <span className="font-bold">{esgScore.peer_esg_score_performance_avg}</span></div>
            </div>
            <div>
                <div className="flex">
                    <div className="flex">
                    <Gauge
                        value={envScore}
                        {...settings}
                        cornerRadius="50%"
                        sx={(theme) => ({
                            [`& .${gaugeClasses.valueText}`]: {
                            fontSize: 24,
                            },
                            [`& .${gaugeClasses.valueArc}`]: {
                            fill: '#52b202',
                            },
                            [`& .${gaugeClasses.referenceArc}`]: {
                            fill: theme.palette.text.disabled,
                            },
                        })}
                        text={
                            ({ value }) => `${envScore} `
                        }
                    />
                    </div>
                    <div className="grid place-items-center ml-8">Environmental</div>
                </div>
                <div className="flex">
                    <div className="flex">
                        <Gauge
                            value={socialScore}
                            {...settings}
                            cornerRadius="50%"
                            sx={(theme) => ({
                                [`& .${gaugeClasses.valueText}`]: {
                                fontSize: 24,
                                },
                                [`& .${gaugeClasses.valueArc}`]: {
                                fill: '#1d4ed8',
                                },
                                [`& .${gaugeClasses.referenceArc}`]: {
                                fill: theme.palette.text.disabled,
                                },
                            })}
                            text={
                                ({ value }) => `${socialScore} `
                            }
                        />
                    </div>
                    <div className="grid place-items-center ml-8">Social</div>
                </div>
               <div className="flex">
                    <div className="flex">
                    <Gauge
                        value={governanceScore}
                        {...settings}
                        cornerRadius="50%"
                        sx={(theme) => ({
                            [`& .${gaugeClasses.valueText}`]: {
                            fontSize: 24,
                            },
                            [`& .${gaugeClasses.valueArc}`]: {
                            fill: '#eab308',
                            },
                            [`& .${gaugeClasses.referenceArc}`]: {
                            fill: theme.palette.text.disabled,
                            },
                        })}
                        text={
                            ({ value }) => `${governanceScore} `
                        }
                    />
                    </div>
                    <div className="grid place-items-center ml-8">Governance</div>
                </div>
            </div>  
           
        </div>
        <div className="flex flex-col">
            {(symbol == "GOOGL" || symbol=="AMZN") &&
            <>
                <div className="text-center text-lg">Temperature - °C (2017 - 2050)</div>
                <div className="text-sm p-4">Our IPCC-based Climate Impact Metric (CIM) forecasts the mean level of global warming caused by an entity over any custom time period. It is calculated by answering what would the global warming of the planet be (as a global mean temperature increase) if all entities performed the same as the entity being analysed. All impacts are expressed in units of degree Celsius (°C) and identify the mean level of global warming since 1855 (i.e. the year the IPCC uses as a base year and the end of the industrial age).</div>
                <div className="h-96 w-full"><RiskTabChart data={graphData}/></div>
            </>
            }
        </div>
    </Panel>
)
}
