import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';

const COLORS = ['#52b202', '#dddddd', '#1d4ed8', '#dddddd', '#eab308', '#dddddd'];

export default class Example extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/pie-chart-with-padding-angle-7ux0o';

  render() {
    const { data } = this.props;
    return (
      <PieChart width={600} height={450} onMouseEnter={this.onPieEnter}>
        <Pie
          data={data}
         
          innerRadius={120}
          outerRadius={200}
          fill="#8884d8"
          paddingAngle={1}
          dataKey="value"
        >
           
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
         <Legend />
        {/* <Pie
          data={data}
          cx={420}
          cy={200}
          startAngle={180}
          endAngle={0}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie> */}
      </PieChart>
    );
  }
}
