import { Search } from "./../pages/components/Search"
import { TopRightDropdown } from "./TopRightDropdown"
import { HamburgerMenu } from "./../layout/images/GeneralIcons"
import { Link } from "react-router-dom"

export const TopMenu = ({username, onMenuToggle}) => {
    const handleMenuToggle = () => {
        onMenuToggle()
    }
    return (  
        <div className="w-full mt-0 h-20 flex items-center justify-center bg-white">
            <div className="w-full flex justify-between">
                <div className="flex md:w-5/6 w-3/4">
                    <Link to = "/dashboard">
                        <div className="ml-6 text-left font-bold text-[#323743] text-[32px] tracking-[0] leading-[48px]">
                            <span className="italic text-[#00AEEF]">i</span>VC
                        </div>
                    </Link>
                    <div className="lg:pl-48 pl-6 lg:block w-12 lg:w-96">
                        <Search/>
                    </div>
                </div>
                <div className="flex md:w-1/6 w-1/4">
                    <div>
                    <TopRightDropdown username={username}/>
                    </div>
                    <div 
                        className="cursor-pointer lg:hidden block w-[36px] h-[36px] top-[12px] right-1 absolute items-center"
                        onClick={() => handleMenuToggle()}
                    >
                        <HamburgerMenu/>
                    </div>
                </div>
            </div>
        </div>
    )
}