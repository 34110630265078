
import React, { useState } from 'react';


const Tabs =({tabs, onSelection}) => {
    const[selected, setSelected] = useState(0)
    const handleClick = (id) => {
        setSelected(id)
        onSelection(id)
    }

    return(
        <div className="text-sm font-medium text-center text-general-80 border-b border-general-40 dark:text-gray-400 dark:border-gray-700">
            <ul className="flex flex-wrap -mb-px">
                {tabs.map((tab, index) => {
                    return(
                        <li key={index} className="me-2">
                            <a href="#" className={`inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${selected === index ? "text-blue-600 border-blue-500" : "border-transparent"}`} onClick={()=>handleClick(index)}>{tab}</a>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default Tabs;