import Input from "../layout/components/Input";
import Button from "../layout/components/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {create} from '../common/BackendProvider';
import {toast, ToastContainer} from "react-toastify";

export const InvestmentProfilesCreate = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    let navigate = useNavigate();

    const handleNameChange = (e) => {
       setName(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleUpdate = () => {
        create("investment-profiles", {data: {'name': name , 'description': description}}).then((response) => {
            setName(response.data.name)
            toast.success("Profile created successfully", {})
            // redirect to the profile page
            navigate(`/investment_profiles`)
            
        }).catch((error) => {
            console.log(error.message)
            toast.error(error.message, {})
        })
    }

    return (
        <div className="p-8">
            <ToastContainer />
            <div className="flex justify-between">
                <div className="text-2xl font-bold mb-8">Create Investment Profile</div>
                <div className="flex items-center"></div>
            </div>
            <div className="self-start p-4 rounded">
                    <div className="form-group mb-4 text-center">
                        <Input 
                            type="text" 
                            label="Name" 
                            id="name" 
                            name="name" 
                            value={name} 
                            onChange={handleNameChange}
                        />
                    </div>
                    <div className="form-group mb-4 text-center">
                        <Input 
                            type="text" 
                            label="Description" 
                            id="description" 
                            name="description" 
                            value={description} 
                            onChange={handleDescriptionChange}
                        />
                    </div>
                    <Button variant="primary" onClick={handleUpdate} >Create</Button>
                </div>
        </div>
    )
}