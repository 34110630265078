import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { Skeleton } from '@mui/material';

export const Gauges = ({gauges, loading=false}) => {
    return (
        gauges.map((gauge, i) => {
            return (
                <div key={i}>
                    <div className="text-center">{gauge.title}</div>
                    {loading && <div className="mx-4 mt-12 mb-12"><Skeleton variant="rounded" width={170} height={105} /></div>}
                    {!loading &&
                    <div className="flex align-center"><Gauge
                        {...gauge.settings}
                        value={gauge.value}
                        cornerRadius={"50%"}
                        sx={(theme) => ({
                            [`& .${gaugeClasses.valueText}`]: {
                            fontSize: 40,
                            },
                            [`& .${gaugeClasses.valueArc}`]: {
                            fill: gauge.color,
                            },
                            [`& .${gaugeClasses.referenceArc}`]: {
                            fill: theme.palette.text.disabled,
                            },
                        })}
                        text={
                            ({ value }) => `${gauge.value} `
                        }
                    /></div>}
                </div>
            )
        })
    )
}