import Select from "../../layout/components/Select"
import { useEffect, useState } from 'react';
import {getList} from "../../common/BackendProvider.jsx";
import { toast, ToastContainer } from 'react-toastify';

export const SearchBar = ({setSector, setIndustry, setRegion, setSize}) => {
    const[sectors, setSectors] = useState([])
    const[industries, setIndustries] = useState([])
    const[regions, setRegions] = useState([])
    const[loading, setLoading] = useState(true)
    const sizeOptions = [
        {id: 0, name: "-- Select size --"},
        {id: 1, name: "Small"},
        {id: 2, name: "Medium"},
        {id: 3, name: "Large"}
    ]
    useEffect(() => {
        setLoading(true)
        getList("sectors").then((response) => {
            setSectors(response.data.sectors)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading sectors", {})
                setLoading(false)
            }
        )
        getList("industries").then((response) => {
            setIndustries(response.data.industries)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading sectors", {})
                setLoading(false)
            }
        )
        getList("regions").then((response) => {
            setRegions(response.data.regions)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading sectors", {})
                setLoading(false)
            }
        )
    },[])
    const handleChange = (value, area) => {
        if (area === "sector") {
            setSector(value)
        } else if (area === "industry") {
            setIndustry(value)
        } else if (area === "region") {
            setRegion(value)
        } else if (area === "size") {
            setSize(value)
        }
    }
    return(
        <div>
            <ToastContainer/>
            <div className="grid grid-cols-4">
                <div className="p-2">
                    <Select options={sectors} onChange={(id)=>{handleChange(id, "sector")}}/>
                </div>
                <div className="p-2">
                    <Select options={industries} onChange={(id)=>{handleChange(id, "industry")}}/>
                </div>
                <div className="p-2">
                    <Select options={regions} onChange={(id)=>{handleChange(id, "region")}}/>
                </div>
                <div className="p-2">
                    <Select options={sizeOptions} onChange={(id)=>{handleChange(id, "size")}}/>
                </div>
            </div>
        </div>
    )
}