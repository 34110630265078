const cx = require('classnames');

export default function Button({ children, className, onClick, variant, disabled, ...rest }) {
    let colors = 'text-primary-100'
    if (variant == 'primary') {
        colors = 'bg-primary-100 text-white'
    } else if (variant == 'danger') {
        colors = 'bg-red-100 text-white'
    } 
    return (
        <button
            onClick={onClick}
            className={cx(`bg-primary-100 border px-4 py-4 rounded-lg`, className, disabled ? 'bg-slate-300 text-white' : colors)}
            {...rest}
            disabled={disabled}
        >
            {children}
        </button>
    );
}