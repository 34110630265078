export const DashboardIcon = ({fill, w, h}) => {
    if (fill === "general-70") {
        fill = "#7E84A3"
    } else if (fill === "white") {
        fill = "#FFFFFF"
    } else if (fill === "primary-100") {
        fill = "#1E5EFF"
    }
    return(
        <svg width={w} height={h} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14 3C15.1046 3 16 3.89543 16 5V7H19C20.1046 7 21 7.89543 21 9V18C21 19.1046 20.1046 20 19 20H5C3.89543 20 3 19.1046 3 18V9C3 7.89543 3.89543 7 5 7H8V5C8 3.89543 8.89543 3 10 3H14ZM19 9H5V18H19V9ZM14 5H10V7H14V5Z" fill={fill}/>
        </svg>
    )
}

export const CompaniesIcon = ({fill, w, h}) => {
    if (fill === "general-70") {
        fill = "#7E84A3"
    } else if (fill === "white") {
        fill = "#FFFFFF"
     } else if (fill === "primary-100") {
        fill = "#1E5EFF"
    }
    return(
        <svg width={w} height={h} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.5459 3.57029C11.9641 3.15205 12.5498 2.9466 13.1377 3.01192L21 3L20.9881 10.8623C21.0534 11.4502 20.848 12.0359 20.4297 12.4541L11.4541 21.4297C10.6937 22.1901 9.46092 22.1901 8.70056 21.4297L2.57027 15.2994C1.80991 14.5391 1.80991 13.3063 2.57027 12.5459L11.5459 3.57029ZM13 5L3.94704 13.9227L10.0773 20.053L19 11V5L13 5ZM14 8L16 10L14 12L12 10L14 8Z" fill={fill}/>
        </svg>
    )
}

export const DollarIcon = ({fill, w, h}) => {
    if (fill === "general-70") {
        fill = "#7E84A3"
    } else if (fill === "white") {
        fill = "#FFFFFF"
    } else if (fill === "primary-100") {
        fill = "#1E5EFF"
    }
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 3C12.5523 3 13 3.44772 13 4L13.0008 4.0903C15.1936 4.49143 16.8893 6.1853 16.9948 8.2915L17 8.5H15C15 7.43378 14.1779 6.49103 13.0014 6.14282L13.0008 11.0903C15.2655 11.5046 17 13.2978 17 15.5C17 17.7022 15.2655 19.4954 13.0008 19.9097L13 20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20L11.0002 19.9099C8.80687 19.5091 7.11069 17.815 7.00521 15.7085L7 15.5H9C9 16.5665 9.82259 17.5095 10.9996 17.8575L11.0002 12.9099C8.73496 12.4959 7 10.7025 7 8.5C7 6.29746 8.73496 4.50405 11.0002 4.09012L11 4C11 3.44772 11.4477 3 12 3ZM13.0012 13.1428L13.0014 17.8572C14.1779 17.509 15 16.5662 15 15.5C15 14.4337 14.1778 13.491 13.0012 13.1428ZM9 8.5C9 9.56657 9.82266 10.5096 10.9998 10.8575L10.9996 6.14252C9.82259 6.4905 9 7.43347 9 8.5Z" fill="#1E5EFF"/>
        </svg>
    )
}

export const CartIcon = ({fill, w, h}) => {
    if (fill === "general-70") {
        fill = "#7E84A3"
    } else if (fill === "white") {
        fill = "#FFFFFF"
    } else if (fill === "primary-100") {
        fill = "#1E5EFF"
    }
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.5 19C8.32843 19 9 19.6716 9 20.5C9 21.3284 8.32843 22 7.5 22C6.67157 22 6 21.3284 6 20.5C6 19.6716 6.67157 19 7.5 19ZM17.5 19C18.3284 19 19 19.6716 19 20.5C19 21.3284 18.3284 22 17.5 22C16.6716 22 16 21.3284 16 20.5C16 19.6716 16.6716 19 17.5 19ZM4.5 2C4.72101 2 4.93448 2.07316 5.10803 2.20608L5.20711 2.29289L7.915 5H20.4895C21.0418 5 21.4895 5.44772 21.4895 6C21.4895 6.13419 21.4625 6.26701 21.4101 6.39055L18.6989 12.7811C18.3854 13.5201 17.6605 14 16.8578 14H8V15L8.00673 15.1166C8.06005 15.5757 8.4243 15.94 8.88338 15.9933L9 16H18L18.1166 16.0067C18.614 16.0645 19 16.4872 19 17C19 17.5128 18.614 17.9355 18.1166 17.9933L18 18H9L8.82373 17.9949C7.30725 17.907 6.09296 16.6928 6.00509 15.1763L6 15V13L6.00673 12.8834C6.01875 12.7799 6.04658 12.6812 6.08775 12.5898C6.03104 12.4041 6 12.2057 6 12V5.915L4.085 4H3C2.44772 4 2 3.55228 2 3V3C2 2.44772 2.44772 2 3 2H4.5ZM18.978 7H8V12H16.8578L18.978 7Z" fill="#1E5EFF"/>
        </svg>
    )
}