const cx = require('classnames');
import {ArrowUp, ArrowDown} from '../../layout/images/Arrows';
import {DashboardIcon, CompaniesIcon, DollarIcon, CartIcon} from '../../layout/images/DashboardIcons';

const Tile = ({ title, value, change, last, trend, icon }) => {
    return (
        <div className={cx("flex border-b lg:border-b-0 px-6 py-4 justify-between", last ? "": "border-r-2 border-general-30")}>
            <div>
            <div className="text-2xl font-bold text-general-100 mt-2">{value}</div>
            <div className="text-general-80 text-sm mb-4">{title}</div>
            <div className="flex">
                <div className={cx("text-sm mr-2", trend === "up" ? "text-green-100" : "text-red-100")}>{change}</div>
                {trend === "up" ? <ArrowUp fill="green-100"/> : <ArrowDown fill="red-100"/>}
            </div>
            </div>
            <div className="content-center">
                <div className="content-center rounded-full bg-primary-30 h-16 w-16">
                    <span className="w-fit block text-center mx-auto">
                    {icon}
                    </span>
                </div>
            </div>
        </div>
    );
}

export const Tiles = () => {
    return (
        <div className="grid lg:grid-cols-4 gap-1 rounded-lg bg-white">
            <Tile title="Portfolio" value="$1,204,300" change="+$20% m/m" trend="up" icon={<DashboardIcon fill="primary-100" w={36} h={36}/>}/>
            <Tile title="Portfolio Companies" value="15" change="+4 m/m" trend="up" icon={<CompaniesIcon fill="primary-100" w={36} h={36}/>}/>
            <Tile title="Watchlist Companies" value="37" change="+7" trend="up" icon={<CartIcon fill="primary-100" w={36} h={36}/>}/>
            <Tile title="Net Profit" value="$183,045" change="+1.5%" trend="up" icon={<DollarIcon fill="primary-100" w={36} h={36}/>} last/>
        </div>
    );
}