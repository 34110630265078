import { useEffect, useState } from 'react';
import {toast, ToastContainer} from "react-toastify";
import { getList } from "../common/BackendProvider.jsx";
import { format } from "d3";
import Table from '../layout/Table.jsx';

export function Watchlist() {
    const[companies, setCompanies] = useState([])
    const[loading, setLoading] = useState(true)
    const loadCompanies = () => {
        setLoading(true)
        getList("watchlist", {pagination:{page:1, perPage:100}}).then((response) => {
            setCompanies(response.data.companies)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading companies", {})
                setLoading(false)
            }
        )
    }

    useEffect(() => {
        loadCompanies()
    },[])

    return (
        <div className="p-2 lg:p-8">
            <div className="text-2xl font-bold mb-8">Watch List</div>
            <div className="bg-white rounded-lg">
                <div className="p-4">
                </div>
                <div className="grid">
                <div className="px-4">
                <Table
                    className="w-full"
                    columns={["Name", "Symbol", "Last Price","ESG Score", "24h High", "24h Low"]}
                    keys={[
                        {name: 'name', type: 'text', format: (d) => (d), class: "text-left", link: "/company/{symbol}"},
                        {name: 'symbol', type: 'text', format: (d) => (d), class: "text-center", link: "/company/{symbol}"},
                        {name: 'last_price', type: 'text', format: (d) => format(",.2f")(d), class: "text-right", link: "/company/{symbol}"},
                        {name: 'esg_score', type: 'text', format: (d) => format(",.0f")(d), class: "text-right", link: "/company/{symbol}"},
                        {name: 'high_24h', type: 'text', format: (d) => format(",.2f")(d), class: "text-right", link: "/company/{symbol}"},
                        {name: 'low_24h', type: 'text', format: (d) => format(",.2f")(d), class: "text-right", link: "/company/{symbol}"}
                    ]}
                    data={companies}
                    readOnly={true}
                    loading={loading}
                />
                </div>
          </div>
        </div>
    </div>
    )
}