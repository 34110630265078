import { Panel } from '../../../../layout/Panel';

export const About = ({company}) => {
    const truncate = (str, n, useWordBoundary ) => {
        if (str.length <= n) { return str; }
        const subString = str.slice(0, n-1); // the original check
        return (useWordBoundary 
            ? subString.slice(0, subString.lastIndexOf(" ")) 
            : subString) + " ... ";
    };
    return(
        <Panel className="">
            <div className="text-left pl-4 text-general-100 text-lg font-bold">
                About {company.symbol}
            </div>
            <div className="text-left pl-4 text-general-100 text-base font-normal">
                {company.description && truncate(company.description, 555, true)}
            </div>
            <div className="text-left pl-4 text-general-100 text-base mt-4">
                <span className="font-bold mr-2">Industry:</span>
                {company.industry?.name}
            </div>
            <div className="text-left pl-4 text-general-100 text-base mt-4">
                <span className="font-bold mr-2">Sector:</span>
                {company.sector?.name}
            </div>
            <div className="text-left pl-4 text-general-100 text-base mt-4">
                <span className="font-bold mr-2">Employees:</span>
                {company.employees}
            </div>
            <div className="text-left pl-4 text-general-100 text-base mt-4">
                <span className="font-bold mr-2">Website:</span>
                <a href={company.website} target="_blank" className="underline">{company.website}</a>
            </div>
        </Panel>
    )
}