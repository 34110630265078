import { useEffect, useState } from 'react';
import {toast, ToastContainer} from "react-toastify";

import { Panel } from '../../../layout/Panel';
import { Pagination } from '../../../layout/Pagination';
import {getList} from '../../../common/BackendProvider';
import { Link } from "react-router-dom";

export const NewsTab =({company}) => {
    const[news, setNews] = useState([])
    const[page, setPage] = useState(1)
    const[pages, setPages] = useState(1)
    const[loading, setLoading] = useState(true)
    useEffect(() => {
        setLoading(true)
        getList("companies/" + company.symbol + "/news" , {pagination:{page:page, perPage:10}}).then((response) => {
            setNews(response.data.news)
            setPages(response.data.pages)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading news", {})
                setLoading(false)
            }
        )
    },[company, page])

    const handlePageChange = (page) => {
        setPage(page)
    }

    return (
        <div>
            {news?.map((n, index) => {
                    const publishedDate = new Date(n.published_at);
                    return <div key={index} className="p-4 bg-white text-gray-600 rounded border-b border-general-40 shadow-shadow-base text-left">
                        <div className="grid grid-cols-[max-content_1fr]">
                            <div className="w-24">
                                {n.image_url &&
                                <img src={n.image_url} className="w-full"/> || <div className="w-full h-16 bg-primary-30"></div>}
                            </div>
                            <div className="ml-4">
                                <div className="text-sm text-general-100">{publishedDate.toLocaleString("en-GB")}</div>
                                <Link to = {`/news/${n.id}`}>
                                <div className="text-base text-general-100 font-bold">{n.headline}</div>
                                </Link>
                                <p className="text-sm text-general-100 text-gray-600">{n.summary}</p>
                            </div>
                        </div>
                    </div>
                })}
                <Panel>
            <div className="mb-4 justify-left mt-4">
                <Pagination page={page} pages={pages} onPageChange={(page) => handlePageChange(page)} />
            </div>
            </Panel>
        </div>
    )
}
