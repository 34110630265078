export const StarActive = () => {
    return (
        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.39301 0.0802627C9.62625 0.179445 9.81219 0.363042 9.91263 0.593353L11.9554 5.27719L17.098 5.74815C17.6445 5.79819 18.0464 6.27618 17.9957 6.81576C17.9722 7.06545 17.8529 7.29679 17.6621 7.46229L13.782 10.828L14.9175 15.8029C15.0382 16.3316 14.702 16.8567 14.1666 16.9758C13.9189 17.031 13.6591 16.9904 13.4408 16.8623L8.99997 14.2586L4.55917 16.8623C4.08728 17.139 3.4776 16.9856 3.19741 16.5196C3.06775 16.304 3.02662 16.0476 3.08245 15.8029L4.21798 10.828L0.337881 7.46229C-0.0744204 7.10465 -0.115035 6.48469 0.247167 6.07757C0.414773 5.88918 0.649067 5.77131 0.901936 5.74815L6.04454 5.27719L8.08731 0.593353C8.30438 0.0956463 8.88896 -0.134072 9.39301 0.0802627Z" fill="#FFC700"/>
        </svg>
    )
}

export const StarInactive = ({fill}) => {
    return (
        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.39301 0.0802627C9.62625 0.179445 9.81219 0.363042 9.91263 0.593353L11.9554 5.27719L17.098 5.74815C17.6445 5.79819 18.0464 6.27618 17.9957 6.81576C17.9722 7.06545 17.8529 7.29679 17.6621 7.46229L13.782 10.828L14.9175 15.8029C15.0382 16.3316 14.702 16.8567 14.1666 16.9758C13.9189 17.031 13.6591 16.9904 13.4408 16.8623L8.99997 14.2586L4.55917 16.8623C4.08728 17.139 3.4776 16.9856 3.19741 16.5196C3.06775 16.304 3.02662 16.0476 3.08245 15.8029L4.21798 10.828L0.337881 7.46229C-0.0744204 7.10465 -0.115035 6.48469 0.247167 6.07757C0.414773 5.88918 0.649067 5.77131 0.901936 5.74815L6.04454 5.27719L8.08731 0.593353C8.30438 0.0956463 8.88896 -0.134072 9.39301 0.0802627Z" fill="#A1A7C4"/>
        </svg>
    )
}
