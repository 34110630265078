import { useEffect, useState } from 'react';
import {toast, ToastContainer} from "react-toastify";
import { Skeleton } from "@mui/material";
import { Panel } from '../../../../layout/Panel';
import { Pagination } from '../../../../layout/Pagination';
import {getList} from '../../../../common/BackendProvider';
import { Link } from "react-router-dom";


export const News = ({company}) => {
    const[news, setNews] = useState([])
    const[page, setPage] = useState(1)
    const[pages, setPages] = useState(1)
    const[loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        getList("companies/" + company.symbol + "/news" , {pagination:{page:page, perPage:10}}).then((response) => {
            setNews(response.data.news)
            setPages(response.data.pages)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading news", {})
                setLoading(false)
            }
        )
    },[company, page])

    const handlePageChange = (page) => {
        setPage(page)
    }

    return(
        <Panel>
            <div className="text-left pl-4 text-general-100 text-lg font-bold">
                News
            </div>
            <div className="mt-4">
                {loading && (
                    Array.from({ length: 10 }, (_, index) => {
                        return (
                        <div key={index} className="gap-1 border-b border-general-40 mx-6 text-center">
                            <div className="py-4 px-1" colSpan={2}>
                                <Skeleton variant="rectangular" width="100%" height={40} sx={{ bgcolor: 'grey.50' }} />
                            </div>
                        </div>)
                    }))
                }
                {!loading && news?.map((n, index) => {
                    const publishedDate = new Date(n.published_at);
                    return ( 
                    <div key={index} className="p-4 bg-white text-gray-600 rounded border-b border-general-40 shadow-shadow-base text-left">
                        <div className="grid grid-cols-[max-content_1fr]">
                            <div className="w-24">
                                <Link to = {`/news/${n.id}`}>
                                {n.image_url &&
                                    <img src={n.image_url} className="w-full"/> || <div className="w-full h-16 bg-primary-30"></div>}
                                </Link>
                            </div>
                            <div className="ml-4">
                                <div className="text-sm text-general-100">{publishedDate.toLocaleString("en-GB")}</div>
                                <Link to = {`/news/${n.id}`}>
                                    <div className="text-base text-general-100 font-bold">{n.headline}</div>
                                    <p className="text-sm text-general-100 text-gray-600">{n.summary}</p>
                                </Link>
                                <div className="text-sm mt-2 flex md:flex-row flex-col gap-2">
                                {n.companies?.length > 1 && <span className="text-sm">Other Companies:</span>}
                                {n.companies?.map((c, index) => {
                                    return(
                                    (c.symbol !== company.symbol) &&
                                        <Link key={index} to={`/company/${c.symbol}`}> 
                                        <span key={index} className="text-sm px-3 py-1 rounded text-general-80 bg-general-40">{c.symbol}</span>
                                        </Link>
                                    )
                                })}
                                </div>
                            </div>
                            
                        </div>
                       
                    </div>
                    )
                })}
                <div className="mb-4 justify-left mt-4">
                    <Pagination page={page} pages={pages} onPageChange={(page) => handlePageChange(page)} loading={loading}/>
                </div>
            </div> 
        </Panel>
    )
}