import { Gauges } from './Gauges';
import { News } from './News';
import { About } from './About';
import { Panel } from '../../../../layout/Panel';
import PieChartWithPaddingAngle from './../PieChartWithPaddingAngle'

export const OverviewTab =({esgScore, riskScore, financialScore, news, company, loading}) => {

    const settings = {
        width: 150,
        height: 150,
        startAngle: -110,
        endAngle: 110
    };
    const gauges = [
        {
            title: "ESG Score",
            value: esgScore,
            color: '#52b202',
            settings: settings
        },
        {
            title: "Financial Score",
            value: financialScore,
            color: '#1d4ed8',
            settings: settings
        },
        {
            title: "Risk Score",
            value: riskScore,
            color: '#eab308',
            settings: settings
        }
    ]
    const totalData = [
        { name: 'ESG', value: esgScore },
        { name: '(ESG)', value: 100-esgScore },
        { name: 'Financial', value: financialScore },
        { name: '(Financial)', value: 100-financialScore },
        { name: 'Risk', value: riskScore },
        { name: '(Risk)', value: 100-riskScore },
    ]
    return(
        <div className="flex flex-col">
            <Panel className="mb-4">
                <div className="lg:flex">
                    <div className="flex flex-col">
                        <div className="text-center font-bold">Aggregated score</div>
                        <PieChartWithPaddingAngle data={totalData}/>
                    </div>
                    <div className="mt-4 w-100 rounded text-left">
                        <div className="w-full ml-4 mt-4 mb-4 lg:flex flex-col justify-between">
                            <div className="lg:flex border-r border-general-40 pr-6" >
                                <Gauges gauges={gauges} loading={loading}/>
                            </div>
                            <div className="grid grid-cols-2 mr-8 content-start">
                                <div className="px-4 py-1 border-b border-general-40">ESG Score</div> 
                                <div className="px-4 py-1 font-bold border-b border-general-40">{esgScore}</div>
                                <div className="px-4 py-1 border-b border-general-40">Financial Score</div> 
                                <div className="px-4 py-1 font-bold border-b border-general-40">{financialScore}</div>
                                <div className="px-4 py-1 border-b border-general-40">Risk Score</div> 
                                <div className="px-4 py-1 font-bold border-b border-general-40">{riskScore}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Panel>
            <div className="lg:flex lg:flex-row">
                <div className='lg:w-2/3'>
                <News company={company} loading={loading}/>
                </div>
                <div className='lg:w-1/3 lg:ml-4' >
                <About company={company}/>
                </div>
            </div>
        </div>
    )
}