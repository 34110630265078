import { TopMenu } from "./../layout/TopMenu.jsx";
import { LeftMenu } from "./LeftMenu.jsx";
import { useState } from "react";
const cx = require('classnames');

export const PrivateHome = ({children, user}) => {
    const username = user ? user.name : '';
    const [menuOpen, setMenuOpen] = useState(false);
    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
    }
    const handleMenuclosed = () => {
        setMenuOpen(false);
    }
    return (
        <div className="bg-[#F5F6FA] flex flex-row justify-center w-full">
            <div className="lg:w-[1440px] w-full">
                <TopMenu username={username} onMenuToggle={handleMenuToggle}/>
                <div className="justify-center relative lg:grid lg:grid-cols-12">
                    <div className={cx("col-span-2 lg:block border-r border-general-40", menuOpen ? "" : "hidden")}> 
                        <LeftMenu onClick={handleMenuclosed}/>
                    </div>
                    <div className="lg:col-span-10 col-span-10">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}